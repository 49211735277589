:root {
    --if_image-gap: 0.5rem;
    --if_mosaic-margin-bottom: 1rem;
    --if_font-family: 'Bodoni Moda', serif;
    /* --if_font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

html {
    box-sizing: border-box;
}

*,
*:after,
*:before {
    box-sizing: inherit;
}


.if-modal-content img,
.if-modal-content video,
.if-post img,
.if-post video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.if-container {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.if-mosaic {
    display: grid;
    grid-template-columns: 384px;
    grid-template-rows: repeat(5, 384px);
    gap: var(--if_image-gap);
    margin-bottom: var(--if_mosaic-margin-bottom);
}

.if-three-in-row {
    display: grid;
    grid-template-columns: 400px;
    grid-template-rows: fit-content(400px);
    gap: var(--if_image-gap);
    margin-bottom: var(--if_image-gap);
}

.if-six-in-row {
    display: grid;
    grid-template-columns: repeat(2, 40vw);
    grid-template-rows: repeat(3, fit-content(40vw));
    gap: var(--if_image-gap);
    margin-bottom: var(--if_image-gap);
}

@media screen and (min-width:768px) {

    .if-mosaic {
        grid-template-columns: calc(384px - var(--if_image-gap)) repeat(2, calc(192px - var(--if_image-gap)));
        grid-template-rows: repeat(2, calc(192px - var(--if_image-gap)));
    }
    /* Odd mosaics have the big post on the left */
    .if-mosaic:nth-child(odd) > .if-post:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    .if-mosaic:nth-child(odd) > .if-post:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
    .if-mosaic:nth-child(odd) > .if-post:nth-child(3) {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }
    .if-mosaic:nth-child(odd) > .if-post:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    .if-mosaic:nth-child(odd) > .if-post:nth-child(5) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }

    /* Even mosaics have the big post on the right */
    .if-mosaic:nth-child(even) {
        grid-template-columns: repeat(2, calc(192px - var(--if_image-gap))) calc(384px - var(--if_image-gap));
    }
    .if-mosaic:nth-child(even) > .if-post:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    .if-mosaic:nth-child(even) > .if-post:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
    .if-mosaic:nth-child(even) > .if-post:nth-child(3) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    .if-mosaic:nth-child(even) > .if-post:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }
    .if-mosaic:nth-child(even) > .if-post:nth-child(5) {
        grid-column: 3 / 4;
        grid-row: 1 / 3;
    }

    /* Three posts per row */
    .if-three-in-row {
        grid-template-columns: repeat(3, 30vw);
        grid-template-rows: 30vw;
    }

    /* Six posts per row */
    .if-six-in-row {
        grid-template-columns: repeat(3, 28vw);
        grid-template-rows: repeat(2, fit-content(28vw));
    }
}

@media screen and (min-width:1200px) {
    /* Six posts per row */
    .if-six-in-row {
        grid-template-columns: repeat(6, 15vw);
        grid-template-rows: 15vw;
    }
}

.if-post {
    overflow: hidden;
    transition: box-shadow 0.5s ease-in;
    position: relative;
}

.if-post img, .if-post video {
    z-index: -9;
    position: relative;
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}

.if-post i {
    position: absolute;
    color: rgba(255, 255, 255, 0.6);
    top: 0;
    right:0;
    font-size: 1.5rem;
    margin: 0.5rem
}

.if-post:hover {
    -webkit-box-shadow: inset 0px 0px 20px 8px rgba(255,255,255,0.79);
    -moz-box-shadow: inset 0px 0px 20px 8px rgba(255,255,255,0.79);
    box-shadow: inset 0px 0px 20px 8px rgba(255,255,255,0.79);
}

.if-post:hover img, .if-post:hover video{
    filter: brightness(120%);
    transform: scale(1.3);
}

/* MODAL */
.if-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 5px; /* Location of the box */
    /* top: 0;
    left: 0; */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    font-family: var(--if_font-family);
}

.if-modal.if-dark {
    background-color: rgba(0,0,0,0.95); /* Black w/ opacity */
    color: #FFFFFF;
    --if_account-color: #FFFFFF;
    --if_slide-button-color: #FFFFFF;
    --if_bg-slide-button-color: rgba(0, 0, 0, 0.4);
    --if_btn-hover: #e6e6e6;
    --if_bg-caption: rgba(0, 0, 0, 0.616);
    --if_bg-caption-expanded: rgba(0, 0, 0, 0.836);
    --if_show-more-color: #e7f5ab;
    --if_show-less-color: #e7f5ab;
}

.if-modal.if-light {
    background-color: rgba(255,255,255,0.9); /* Black w/ opacity */
    color: #000000;
    --if_account-color: #000000;
    --if_slide-button-color: #000000;
    --if_bg-slide-button-color: rgba(255, 255, 255, 0.4);
    --if_btn-hover: #333333;
    --if_bg-caption: rgba(255, 255, 255, 0.9);
    --if_bg-caption-expanded: rgba(255, 255, 255, 0.9);
    --if_show-more-color: #222222;
    --if_show-less-color: #222222;
}

.if-modal.if-light-blue {
    background-color: rgba(255,255,255,0.93); /* Black w/ opacity */
    color: #000000;
    --if_account-color: #1CC4DB;
    --if_slide-button-color: #1CC4DB;
    --if_bg-slide-button-color: rgba(255, 255, 255, 0.4);
    --if_btn-hover: #1CC4DB;
    --if_bg-caption: #1cc5db36;
    --if_bg-caption-expanded: #a7d8dde5;
    --if_show-more-color: #1096a0;
    --if_show-less-color: #1096a0;
}

.if-modal-visible {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
}

.if-modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 10%;
}

@media screen and (min-width:768px) {
    .if-modal-actions {
        margin-bottom: 0;
    }
}

.if-modal-content-container {
    position: relative;
}

.if-modal-content-container #if-slide-counter {
    position: absolute;
    top: -45px;
    right: 20px;
    color: var(--if_slide-button-color);;
    background-color: var(--if_bg-slide-button-color);
    padding: 0.5rem;
    border-radius: 20px;
    font-weight: 600;
    font-size: 1.2rem;
}

.if-modal-content-container .if-slide-button {
    position: absolute;
    top: 50%;
    color: var(--if_slide-button-color);
    background-color:var(--if_bg-slide-button-color);
    padding: 0.5rem 1.2rem;
    font-size: 2rem;
    border-radius: 60px;
    font-weight: 600;
}

.if-slide-button:hover {
    cursor: pointer;
}

.if-slide-button#if-prev-button {
    left: 5px
}

.if-slide-button#if-next-button {
    right: 5px
}

.if-slide-button i {
   pointer-events: none;
}

.if-modal-content {
    margin: auto;
    display: block;
    max-width: 80%;
}

/* MAKE SURE ANY IMAGE SIZE FITS THE SCREEN */
@media screen and (min-width:900px) {
    .if-modal-content {
        max-height: 70%;
        width: auto;
    }
    .if-modal-content-container {
        height: calc(100% - 73px);
    }
    .if-modal-content-container #if-slide-counter {
        top: 1%;
        right: 7%;
    }
    .if-modal-content-container .if-slide-button {
        top: 30%;
        padding: 0.5rem 1.4rem;
        font-size: 2.5rem;
    }
    .if-slide-button#if-next-button {
        right: 7%;
    }
    .if-slide-button#if-prev-button {
        left: 7%;
    }
}

/* BUTTONS AT THE TOP OF THE MODAL */
.if-open-instagram {
    display: flex;
    color: var(--if_account-color);
    font-size: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.if-open-instagram a i {
    margin-right: 0.5rem;
}

.if-open-instagram a {
    margin: 0 2rem 0 0;
    text-decoration: none;
    color: inherit;
}

.if-close-button {
    color: inherit;
    background: unset;
    border: none;
    font-size: 3rem;
}

.if-close-button:hover {
    color: var(--if_btn-hover);
}

/* MODAL CAPTION */
.if-modal-caption {
    background-color: var(--if_bg-caption);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
    left: 5%;
    max-width: 90%;
}

.if-modal-caption-expanded {
    background-color: var(--if_bg-caption-expanded);
}

@media screen and (min-width:768px) {
    .if-modal-caption {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}

.if-modal-caption a {
    color: inherit;
    text-decoration: none;
}

.if-modal-caption p {
    color: inherit;
    text-decoration: none;
}

.if-modal-caption .if-show-more {
    color: var(--if_show-more-color);
    font-weight: bold;
    cursor: pointer;
}

.if-modal-caption .if-show-less {
    color: var(--if_show-less-color);
    font-weight: bold;
    cursor: pointer;
}
